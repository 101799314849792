import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { color } from "../theme/theme.utils";

const StyledFooterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LinkStyles = css`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${color.gold};
  }
`;

const StyledListElement = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
  align-items: center;
  justify-content: left;
  gap: 16px;
  color: ${color.white};
  ${({ isLink }) => isLink && LinkStyles}
`;

const StyledListElementTitle = styled.p`
  margin: 0;
`;

const StyledExternalLink = styled.a`
  text-decoration: none;
`;

const StyledTitle = styled.h3`
  color: ${color.white};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

const FooterList = ({ title = "", listElements = [] }) => {
  const elements = listElements.map((el) =>
    el.url ? (
      <StyledExternalLink href={el.url} target="_blank" rel="noreferrer">
        <StyledListElement isLink>
          {el.icon && !el.image ? (
            <FontAwesomeIcon icon={el.icon} />
          ) : el.image ? (
            <img src={el.image} alt="icon" />
          ) : (
            <div />
          )}
          <StyledListElementTitle>{el.title}</StyledListElementTitle>
        </StyledListElement>
      </StyledExternalLink>
    ) : (
      <StyledListElement>
        {el.icon ? <FontAwesomeIcon icon={el.icon} /> : <div />}
        <StyledListElementTitle>{el.title}</StyledListElementTitle>
      </StyledListElement>
    )
  );

  return (
    <StyledFooterListContainer>
      {title && <StyledTitle>{title}</StyledTitle>}
      {elements}
    </StyledFooterListContainer>
  );
};

export default FooterList;
