import React from "react";
import styled from "styled-components";
import { color } from "../theme/theme.utils";
import { graphql, useStaticQuery } from "gatsby";
import { SRLWrapper } from "simple-react-lightbox";

const StyledFooterGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTitle = styled.h3`
  color: ${color.white};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

const StyledGallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px;
`;

const StyledFooterImage = styled.img`
  width: 180px;
  height: 120px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
`;

const FooterGallery = ({ title }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { regex: "/footer-gallery/" } }) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              fluid(maxWidth: 1920) {
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges.map(
    (
      {
        node: {
          name,
          childImageSharp: {
            fluid: { src, srcSet, sizes },
          },
        },
      },
      index
    ) =>
      index < 6 && (
        <a href={src} key={src}>
          <StyledFooterImage src={src} />
        </a>
      )
  );

  return (
    <StyledFooterGalleryContainer>
      {title && <StyledTitle>{title}</StyledTitle>}
      <SRLWrapper>
        <StyledGallery>{images}</StyledGallery>
      </SRLWrapper>
    </StyledFooterGalleryContainer>
  );
};

export default FooterGallery;
