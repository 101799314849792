import React from "react";
import styled, { css } from "styled-components";
import { color } from "../theme/theme.utils";

const StyledHamburgerContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 2rem;
  right: 1rem;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

const BurgerLine = css`
  position: absolute;
  width: 25px;
  height: 3px;
  background: ${color.black};
  transition: 0.5s;
`;

const StyledHamburger = styled.div`
  ${BurgerLine};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ isOpen }) => isOpen && "background-color: transparent;"}
  &:after {
    ${BurgerLine};
    content: "";
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    top: ${({ isOpen }) => (isOpen ? "0" : "8px")};
    ${({ isOpen }) => isOpen && "transform: rotate(135deg);"}
  }

  &:before {
    ${BurgerLine};
    content: "";
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    top: ${({ isOpen }) => (isOpen ? "0" : "-8px")};
    ${({ isOpen }) => isOpen && "transform: rotate(45deg);"}
  }
`;

const Hamburger = ({ isOpen = false, onClick }) => (
  <StyledHamburgerContainer onClick={onClick}>
    <StyledHamburger isOpen={isOpen} />
  </StyledHamburgerContainer>
);

export default Hamburger;
