import WeseleZKlasa from "../images/weselezklasa.png";

export const socialList = [
  {
    url: "https://www.facebook.com/Cam-Story-Filmowanie-Fotografia-%C5%9Blubna-114220823322106/",
    title: "CamStory",
    icon: ["fab", "facebook-f"],
  },
  {
    url: "https://www.facebook.com/Cam-Story-Filmowanie-Fotografia-%C5%9Blubna-114220823322106/",
    title: "#CAMSTORY",
    icon: ["fab", "instagram"],
  },
  {
    url: "https://www.facebook.com/Cam-Story-Filmowanie-Fotografia-%C5%9Blubna-114220823322106/",
    title: "OfficialCamStory",
    icon: ["fab", "youtube"],
  },
  {
    url: "https://www.weselezklasa.pl/ogloszenia-weselne/cam-story-filmowanie-fotografia-4k-dron,44502/",
    title: "Wesele z klasą",
    image: WeseleZKlasa,
  },
];

export const contactList = [
  {
    title: "85-446 Bydgoszcz",
    icon: "location-dot",
  },
  {
    title: "ul. Cietrzewia 1",
  },
  {
    title: "+48 530 565 556",
    icon: "phone-flip",
  },
  {
    title: "+48 530 955 995",
  },
  {
    title: "kontakt@camstory.pl",
    icon: "envelope",
  },
];
