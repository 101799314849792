import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { color, greaterOrEqual } from "../theme/theme.utils";
import Navbar from "./navbar";
import SocialBar from "./social-bar";

const StyledMenuContainer = styled.nav`
  height: 100vh;
  width: 100vw;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${color.darkerWhite};
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-100vw")};
  transition: 0.5s;
  z-index: 900;

  ${greaterOrEqual("md")} {
    position: sticky;
    width: auto;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;
const Menu = ({ isOpen }) => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  return (
    <StyledMenuContainer isOpen={isOpen}>
      <StyledHeader>
        <img
          src={data.file.childImageSharp.fluid.src}
          srcSet={data.file.childImageSharp.fluid.srcSet}
          sizes={data.file.childImageSharp.fluid.sizes}
          alt={data.file.name}
        />
        <Navbar />
      </StyledHeader>

      <SocialBar />
    </StyledMenuContainer>
  );
};

export default Menu;
