const color = {
  white: "#ffffff",
  darkerWhite: "#f5f5f5",
  lightGrey: "#ededed",
  grey: "#bfbfbf",
  darkGray: "#555555",
  softBlack: "#242423",
  black: "#151515",
  gold: "#ccb78f",
  grayedGold: "#9a9893",
};

const gridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const greaterOrEqual = (size) =>
  `@media(min-width: ${gridBreakpoints[size] || 0}px)`;

export { greaterOrEqual, gridBreakpoints, color };
