import { library } from "@fortawesome/fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationDot,
  faPhoneFlip,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  // Brand
  faInstagram,
  faFacebookF,
  faYoutube,

  // Solid
  faLocationDot,
  faPhoneFlip,
  faEnvelope
);
