import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
  }
`;

export default GlobalStyle;
