import React, { useState } from "react";
import styled from "styled-components";
import SimpleReactLightbox from "simple-react-lightbox";
import { greaterOrEqual } from "../theme/theme.utils";
import "../theme/initFA";
import GlobalStyle from "../theme/globalStyles";
import { useResponsive } from "../theme/responsive-hook";
import Hamburger from "../components/hamburger";
import Menu from "../components/menu";
import Footer from "../components/footer";

const StyledLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${greaterOrEqual("md")} {
    grid-template-columns: 320px 4fr;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  overflow-x: hidden;
`;

const StyledContent = styled.div`
  width: 100%;
`;

const MainLayout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile } = useResponsive();
  return (
    <StyledLayoutContainer>
      <SimpleReactLightbox>
        <GlobalStyle />
        {isMobile && (
          <Hamburger
            isOpen={isMenuOpen}
            onClick={() => setIsMenuOpen((prevState) => !prevState)}
          />
        )}
        <Menu isOpen={isMenuOpen} />
        <StyledContentWrapper>
          <StyledContent>{children}</StyledContent>
          <Footer />
        </StyledContentWrapper>
      </SimpleReactLightbox>
    </StyledLayoutContainer>
  );
};

export default MainLayout;
