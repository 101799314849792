import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import { color } from "../theme/theme.utils";
import { routes } from "../data/routes";

const StyledNavbarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  color: ${color.darkGray};
  font-weight: 600;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #151515;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
  }

  &.active {
    &:before {
      transform: scaleX(1);
    }

    color: ${color.black};
  }

  &:hover:before {
    transform: scaleX(1);
  }
}
`;

const navElements = routes.map((route) => (
  <div key={`nav-route__${route.title}`}>
    <StyledLink
      to={route.url}
      activeClassName={!route.disableActive && "active"}
    >
      {route.title}
    </StyledLink>
  </div>
));

const Navbar = () => {
  return <StyledNavbarContainer>{navElements}</StyledNavbarContainer>;
};

export default Navbar;
