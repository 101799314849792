import React from "react";
import styled from "styled-components";
import { color, greaterOrEqual } from "../theme/theme.utils";
import FooterList from "./footer-list";
import { contactList, socialList } from "../data/footerLists";
import FooterGallery from "./footer-gallery";
import { useResponsive } from "../theme/responsive-hook";

const StyledFooterContainer = styled.footer`
  background-color: ${color.softBlack};
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const StyledGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  justify-content: space-around;
  gap: 40px;

  ${greaterOrEqual("sm")} {
    grid-template-columns: auto auto;
  }

  ${greaterOrEqual("xxl")} {
    grid-template-columns: auto auto auto;
  }
`;

const StyledRights = styled.p`
  color: ${color.lightGrey};
  font-size: 0.8rem;
`;

const Footer = () => {
  const { isExtraLarge } = useResponsive();
  return (
    <StyledFooterContainer>
      <StyledGridContainer>
        {isExtraLarge && <FooterGallery title="wybrane projekty" />}
        <FooterList title="znajdź nas" listElements={socialList} />
        <FooterList title="skontaktuj się z nami" listElements={contactList} />
      </StyledGridContainer>
      <StyledRights>Copyright ©2022 All rights reserved</StyledRights>
    </StyledFooterContainer>
  );
};

export default Footer;
