import React from "react";
import { color } from "../theme/theme.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledSocialBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ position }) => position};
  gap: 48px;
  font-size: ${({ size }) => size};

  a {
    text-decoration: none;
    color: ${color.black};
  }
`;

const SocialBar = ({ position = "center", size = "1rem", ...rest }) => {
  return (
    <StyledSocialBarContainer position={position} size={size} {...rest}>
      <a
        href="https://www.facebook.com/Cam-Story-Filmowanie-Fotografia-%C5%9Blubna-114220823322106/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "instagram"]} />
      </a>
      <a
        href="https://www.facebook.com/Cam-Story-Filmowanie-Fotografia-%C5%9Blubna-114220823322106/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "facebook-f"]} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCZv_nX8aEX8TxIH78FcOoQA"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "youtube"]} />
      </a>
    </StyledSocialBarContainer>
  );
};

export default SocialBar;
