import { useMediaQuery } from "react-responsive";
import { gridBreakpoints } from "./theme.utils";
import { useEffect, useState } from "react";

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [isLarge, setIsLarge] = useState(false);
  const [isExtraLarge, setIsExtraLarge] = useState(false);
  const isMediaQueryExtraLarge = useMediaQuery({
    query: `(min-width: ${gridBreakpoints.xxl}px`,
  });
  const isMediaQueryLarge = useMediaQuery({
    query: `(min-width: ${gridBreakpoints.lg}px)`,
  });
  const isMediaQueryMedium = useMediaQuery({
    query: `(min-width: ${gridBreakpoints.md}px)`,
  });
  const isMediaQueryMobile = !isLarge && !isMedium && !isExtraLarge;

  useEffect(() => {
    setIsMobile(isMediaQueryMobile);
    setIsMedium(isMediaQueryMedium);
    setIsLarge(isMediaQueryLarge);
    setIsExtraLarge(isMediaQueryExtraLarge);
  }, [
    isMediaQueryExtraLarge,
    isMediaQueryLarge,
    isMediaQueryMedium,
    isMediaQueryMobile,
  ]);

  return { isMobile, isMedium, isLarge, isExtraLarge };
};

export { useResponsive };
